import * as React from 'react'
import PropTypes from 'prop-types'

import Accordion from '../Accordion'

const Questions = ({ questions }) => (
  <div className="container">
    {questions.map(question => (
      <section className="question">
        <Accordion title={question.title} content={question.text} />
      </section>
    ))}
  </div>
)

Questions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default Questions
