import React, { useState } from 'react'

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className="card">
      <header
        className="card-header"
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
        role="presentation"
      >
        <h3 className="card-header-title has-text-primary-dark">{title}</h3>
        <button className="card-header-icon" aria-label="more options">
          <span className="icon">{isActive ? '-' : '+'}</span>
        </button>
      </header>
      {isActive && (
        <div className="card-content section-gradient has-text-white">
          <div className="content">{content}</div>
        </div>
      )}
    </div>
  )
}

export default Accordion
